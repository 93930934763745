<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form id="senhaForm" @submit.prevent="handleSubmit(submit)">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>contacts</md-icon>
          </div>
          <h4 class="title">Alterar Senha</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-checkbox v-model="checkboxAutoGerarSenha"
                >Auto gerar a senha ?</md-checkbox
              >
              <md-checkbox
                v-model="form.enviarEmail"
                v-show="isPerfilUserSame()"
                >Enviar por email ao usuário ?</md-checkbox
              >
              <ValidationProvider
                name="form.senha"
                :rules="{
                  regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/,
                  min: 8,
                  required: true,
                  confirmed: 'form.confirmacao'
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Senha</label>
                  <md-input
                    ref="senha"
                    v-model="form.senha"
                    type="password"
                    autocomplete="off"
                  >
                  </md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>

              <ValidationProvider
                vid="form.confirmacao"
                name="form.confirmacao"
                rules="min:8|required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Confirmar Senha</label>
                  <md-input
                    ref="confirmacao"
                    v-model="form.confirmacao"
                    type="password"
                    autocomplete="off"
                  >
                  </md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
        </md-card-content>
        <md-card-actions>
          <div class="md-layout">
            <div class="md-layout-item md-size-100 text-right">
              <md-button type="submit" class="md-raised md-success mt-4">
                Alterar
              </md-button>
              <span>&nbsp;</span>
              <md-button @click="reset()" class="md-raised mt-4">
                LIMPAR
              </md-button>
            </div>
          </div>
        </md-card-actions>
      </md-card>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { mapState, mapActions } from "vuex";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  if (key === "regex") {
    pt_br.messages.regex =
      "A senha requer 1 de cada dos seguintes itens: 1x letra maiúscula, 1x letra minúscula, 1x número, 1x caractere especial.";
  }
}
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

///^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})$/
export default {
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      senhaAutoGerada: "",
      size: "12",
      characters: "a-z,A-Z,0-9,#",
      checkboxAutoGerarSenha: false,
      mensagem: null,
      papel: "",
      form: {
        idUsuario: "",
        senha: "",
        confirmacao: "",
        enviarEmail: false
      }
    };
  },
  methods: {
    ...mapActions("user", ["ActionPUTChangePass"]),
    getUser() {
      this.mensagem = null;
      this.form.idUsuario = this.id;
      this.papel = this.user_profile_state.papel;
    },
    reset() {
      this.form.senha = this.form.confirmacao = "";
      this.checkboxAutoGerarSenha = false;
      this.form.enviarEmail = false;
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
    isPerfilUserSame() {
      if (this.id === this.user_profile_state.idUsuario) {
        return false;
      } else {
        return true;
      }
    },
    async submit() {
      try {
        let papel = this.papel;
        let id = this.form.idUsuario;
        let form = this.form;
        let response = await this.ActionPUTChangePass({ id, papel, form });
        if (response.status === 200) {
          this.mensagem = response.body.mensagem;
          if (!response.body.mensagem.includes("Erro")) {
            Swal.fire({
              //title: "Sucesso",
              icon: "success",
              text: this.mensagem,
              customClass: {
                confirmButton: "md-button md-success btn-fill"
              },
              buttonsStyling: false
            });
          } else {
            Swal.fire({
              title: "Erro!",
              icon: "error",
              text: this.mensagem,
              customClass: {
                confirmButton: "md-button md-danger btn-fill"
              },
              buttonsStyling: false
            });
          }
          this.reset();
          window._Vue.$router.go(-1);
        }
      } catch (err) {
        console.log(err);
        if (
          err === 400 ||
          err === 500 ||
          err === 404 ||
          err.status === 400 ||
          err.status === 500 ||
          err.status === 404
        ) {
          this.mensagem = "Aplicação temporariamente indisponível.";
        } else if (err.status === 401 || err.data === "Unauthorized") {
          this.mensagem = "Operação não Autorizada";
        } else {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else if (err.status === 0) {
            this.mensagem =
              "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
          } else if (err.status === 400) {
            this.mensagem = err.body.mensagem;
          } else {
            this.mensagem =
              "Operação temporariamente indisponível tente mais tarde.";
          }
        }
        Swal.fire({
          title: "Erro!",
          icon: "error",
          text: this.mensagem,
          customClass: {
            confirmButton: "md-button md-danger btn-fill"
          },
          buttonsStyling: false
        });
        this.reset();
      }
    },
    gerarSenha() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0) {
        CharacterSet += "#?!@$%^&*-";
      }
      let passed = true;
      while (passed) {
        for (let i = 0; i < this.size; i++) {
          password += CharacterSet.charAt(
            Math.floor(Math.random() * CharacterSet.length)
          );
        }

        let regexCheck = password.match(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/
        );
        if (regexCheck != null && regexCheck.length === 1) {
          passed = false;
        } else {
          password = "";
        }
      }
      return (this.senhaAutoGerada = password);
    }
  },
  mounted() {
    this.getUser();
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapState("user", ["usuario"]),
    user_profile_state: state => state.user_profile,
    userState: state => state.usuario
  },
  watch: {
    checkboxAutoGerarSenha(value) {
      if (value) {
        this.senhaAutoGerada = this.gerarSenha();
        this.form.senha = this.senhaAutoGerada;
        this.form.confirmacao = this.senhaAutoGerada;
      } else {
        this.form.senha = this.form.confirmacao = "";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
#error {
  color: red;
  font-size: 0.75rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}
.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.material-icons.md-48 {
  font-size: 48px;
}
.material-icons.md-light {
  color: red;
}

::v-deep .md-checkbox .md-checkbox-label {
  color: #000;
}
</style>
